<template>
  <v-data-table
    :fixed-header="false"
    :height="undefined"
    v-if="providerId"
    v-bind="dataTableAttrs"
    :headers="headers"
    :items="list"
    :loading="isLoading"
    :options.sync="options"
    :server-items-length="total"
    :items-per-page.sync="itemsPerPage"
    v-model="selectedItems"
    @update:items-per-page="handleItemsPerPageUpdated"
  >
    <template v-slot:top>
      <rs-table-title-bar
        class="mt-6"
        v-if="$refs.headSearch"
        title="Otomatik Gider Tahakkuk Listesi"
        icon=""
        @clear-filters="$refs.headSearch.reset"
        @reload="loadList"
        show-delete
        :edit-enabled="selectedItems.length === 1"
        :delete-enabled="selectedItems.length > 0"
        :search.sync="search.query"
        add-route="#"
        @click:add="handleAddClick"
        @click:edit="handleEditClick"
        @click:delete="handleBatchDeleteClick"
      />

      <AutoAssessmentForm
        @saved="loadList()"
        :provider="originalData"
        ref="autoAssessmentForm"
      />

      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDeleteConfirmed"
        @cancelled="$refs.confirmDelete.hide()"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.prepend="{ headers }">
      <rs-table-head-search
        :headers="headers"
        :search.sync="search"
        ref="headSearch"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.append="{ headers }">
      <rs-table-foot-totals :headers="headers" :totals="footTotals" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.edit="{ item }">
      <a
        href="#"
        class="btn btn-icon btn-sm btn-clean"
        @click.prevent="handleEditClick(item)"
      >
        <i class="menu-icon mdi mdi-pencil"></i>
      </a>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.is_active="{ value }">
      <rs-table-cell-boolean :value="value" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.amount="{ value }">
      <rs-table-cell-number price :value="value" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.starts_on="{ value }">
      <rs-table-cell-date :value="value" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.ends_on="{ value }">
      <rs-table-cell-date :value="value" />
    </template>
    <!-- eslint-disable -->
    <template
      v-slot:item.last_payment_auto_assessment_timing_type_id="{ item }"
    >
      <!-- eslint-enable -->
      <span :title="item.last_payment_day || null">
        {{ item.last_payment_auto_assessment_timing_type }}
      </span>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.issue_auto_assessment_timing_type_id="{ item }">
      <span :title="item.issue_day || null">
        {{ item.issue_auto_assessment_timing_type }}
      </span>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.expense_type_id="{ item }">
      {{ item.expense_type }}
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.child_expense_type_id="{ item }">
      {{ item.child_expense_type }}
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.invoiced_type_id="{ item }">
      {{ item.invoiced_type }}
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import hasDataTable from "@/view/mixins/hasDataTable";
import AutoAssessmentForm from "./AutoAssessmentForm";

export default {
  name: "AutoAssessmentList",
  mixins: [hasDataTable],
  components: {
    AutoAssessmentForm,
  },
  props: {
    providerId: {
      type: Number,
      required: true,
    },
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    providerId: {
      handler() {
        this.loadList();
      },
    },
  },
  computed: {
    ...mapGetters([
      "autoAssessmentTimingTypeList",
      "expenseTypeList",
      "invoicedTypeList",
      "paymentTypeList",
    ]),
  },
  data() {
    return {
      options: {
        sortBy: ["created_at"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Düzenleme Hesap Tipi",
          value: "issue_auto_assessment_timing_type_id",
          searchable: "multiselect",
          options: () => this.autoAssessmentTimingTypeList,
        },
        {
          text: "Ödeme Zamanlama Tipi",
          value: "last_payment_auto_assessment_timing_type_id",
          searchable: "multiselect",
          options: () => this.autoAssessmentTimingTypeList,
        },
        {
          text: "Gider Türü",
          value: "expense_type_id",
          searchable: "multiselect",
          options: () => this.expenseTypeList,
        },
        {
          text: "Gider Tipi",
          value: "child_expense_type",
          searchable: "text",
        },
        {
          text: "Tutar",
          value: "amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Fatura Tipi",
          value: "invoiced_type_id",
          searchable: "multiselect",
          options: () => this.invoicedTypeList,
        },
        {
          text: "Tesisat No",
          value: "utility_no",
          searchable: "text",
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: "text",
        },
        {
          text: this.$t("labels.status"),
          value: "is_active",
          searchable: "isActive",
        },
        {
          text: "Başlangıç Tarihi",
          value: "starts_on",
          searchable: "date",
        },
        {
          text: "Bitiş Tarihi",
          value: "ends_on",
          searchable: "date",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.providerId) {
        return;
      }

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );
      params.provider_id = [this.providerId];
      this.resetTable();
      this.isLoading = true;

      this.$api
        .query("expense/periodical-expenses", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleAddClick() {
      this.$refs.autoAssessmentForm.show(null, {
        provider_id: this.providerId,
      });
    },
    handleEditClick(item) {
      if (item.id) {
        this.$refs.autoAssessmentForm.show(item.id);
      } else {
        this.$refs.autoAssessmentForm.show(this.selectedItems[0].id);
      }
    },
    handleBatchDeleteClick() {
      this.$refs.confirmDelete.show(
        `${this.selectedItems.length} tane otomatik gideri silmek istediğinizden emin misiniz?`
      );
    },
    handleDeleteConfirmed() {
      if (this.isLoading) return false;

      this.isLoading = true;
      const promises = [];

      for (const index in this.selectedItems) {
        const promise = this.$api.delete(
          `expense/periodical-expenses/${this.selectedItems[index].id}`
        );
        promises.push(promise);
      }

      Promise.all(promises)
        .then(() => {
          this.$toast.success("Seçilen otomatik giderler silindi");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.$refs.confirmDelete.hide();
          this.isLoading = false;
          this.loadList();
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
};
</script>
